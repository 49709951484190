import React from "react";
import { Footer } from "flowbite-react";

function FooterSite() {
  const y = new Date().getFullYear();
  return (
    <div className=" mt-12 bottom-0">

      <footer className="bg-white rounded-lg shadow dark:bg-gray-900">
        <div className="w-full  mx-auto p-4 md:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
          <div  className="flex items-center mb-3 md:mb-0">
						<img
							src={require("../../../assets/logo/3.png")}
							className="h-12"
							alt="Logo"
						/>
						<p className=" text-primary text-3xl ml-6 font-titan">Pedia<span className="text-secondary">3</span></p>
					</div>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© {y} <a href="https://webinal.it" className="hover:underline">Webinal™</a></span>
        </div>
      </footer>


    </div>
  );
}

export default FooterSite;
